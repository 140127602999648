import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Table } from 'reactstrap';
import { getCryptoIcon } from "../../../../common/getCryptoIcon";

const getBlockchainName = (symbol) => {
  switch (symbol) {
    case 'BTC':
      return 'Bitcoin';
    case 'ETH':
    case 'USDT':
    case 'USDC':
    case 'DAI':
      return 'Ethereum';
    case 'LTC': 
    return 'Litecoin'
    case 'XRP':
      return 'Ripple'
    case 'TRX':
    case 'TRC20-USDT':
        return 'Tron'
    default:
      alert("Error while fetching blockchain name.")
      return 'N/A';
  }
};
const b2cBalanceTable = (props) => {
  const [balancesDailogue, setBalancesDailogue] = useState(false);
  const [balancesData, setBalancesData] = useState([]);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const b2cTheme = useSelector((state) => state.common.b2cTheme);
  const buyIcon = b2cTheme === 'light' ? '/homeb2c/b2cwalletdepositiconblack.svg' : '/homeb2c/b2cwalletdownarrow.svg';
  const sendIcon = b2cTheme === 'light' ? '/homeb2c/b2cwalletsendblackicon.svg' : '/homeb2c/b2cwalletsendicon.svg';
  const receiveIcon = b2cTheme === 'light' ? '/homeb2c/b2cwalletbuyblackicon.svg' : '/homeb2c/b2cwalletwhitegiftbox.svg';

  return (
    <div className="BalanceTableMain">
      {props.data && props.data.length > 0 ? (
        <div>
          <MediaQuery maxWidth={767}>
            {props.data.map((data, i) => {
              return (
                <div key={i} className="SingleBalancesRowWrap">
                  <div
                    className="SingleBalancesRow"
                    onClick={() => {
                      setBalancesDailogue(true);
                      setBalancesData(data);
                    }}
                  >
                    <div className="balancesFirstRow">
                      <div className="bicoinIconWrap">
                        <img className="USTImages" src={`${image_base_url}${getCryptoIcon(data.coin)}`} alt="bitcoin" />
                        <span>{data.cryptoName}</span>
                      </div>
                      <div className="price">${parseFloat(data.exchangeRate).toFixed(2)}</div>
                    </div>
                    <div className="balancesSecondRow">
                      <div className="amountInCrypto">{`${data.amount || data.amount === 0 ? parseFloat(data.amount).toFixed(6) : '-'} ${data.coin}`}</div>
                      <div className="holdingsPercentage" style={{ color: '#10B981' }}>
                        {parseFloat(data.holding).toFixed(2)}%
                      </div>
                    </div>
                    <div className="balancesThirdRow">
                      <div className="USDAmount">~{data.USDAmount || data.USDAmount === 0 ? parseFloat(data.USDAmount).toFixed(2) : '-'} USD</div>
                      <div className="change"></div>
                    </div>
                  </div>
                </div>
              );
            })}
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <Table responsive>
              <tbody className="BalanceTable_BODY">
                <tr style={{ height: '50px' }}>
                  <th className="BalanceTable_heading" width="17%">
                    Assets{' '}
                  </th>
                  <th className="BalanceTable_heading" width="20%">
                    Holding (%)
                  </th>
                  <th className="BalanceTable_heading" width="15%">
                    Amount
                  </th>
                  <th
                    className="BalanceTable_heading cursor-pointer"
                    width="13%"
                    onClick={() => {
                      props.changeOrder('price');
                    }}
                  >
                    Price {props.sortBy === 'price' && <SortByIcon orderBy={props.orderBy} />}
                  </th>
                  <th className="BalanceTable_heading" width="22%">
                    {' '}
                  </th>
                </tr>
                {props.data.map((TData, i) => {
                    return (
                      <SingleTransactionTableRow
                         data={TData}
                         coinFridge = {props.coinFridge}
                         setCoinFridge={props.setCoinFridge}
                         key={i}
                         showBuyButton={props.showBuyButton}
                         handleSend={props.handleSend}
                         handleReceive={props.handleReceive}
                         setCoin={props.setCoin}
                         setBlockchain={props.setBlockchain}
                         buyIcon={buyIcon}
                         sendIcon={sendIcon}
                         receiveIcon={receiveIcon}
                       />
                     );
                })}
              </tbody>
            </Table>
          </MediaQuery>
          <div>
            <Dialog open={balancesDailogue} className="balancesMobileViewDailogue transaction__Detail__model b2c-signup-model b2c-login-model">
              <div className="details__model details__model_mobile_view balances-mobile-view">
                <div className="details__close__icon">
                  <ClearIcon
                    onClick={() => {
                      setBalancesDailogue(false);
                      setBalancesData([]);
                    }}
                    className="icon__close"
                  />
                </div>
                <div>
                  <img className="USTImages" src={`${image_base_url}${getCryptoIcon(balancesData.coin)}`} alt="bitcoin" width={60} height={60} />
                </div>
                <div className="mt-10 mb-10">
                  <span>{balancesData.coin}</span> | <span>{balancesData.cryptoName}</span>
                </div>
                <div className="balancesDataRow">
                  <div>Price: </div>
                  <div> {parseFloat(balancesData.exchangeRate).toFixed(2)}</div>
                </div>
                <div className="balancesDataRow">
                  <div>Holdings(%) </div>
                  <div> {parseFloat(balancesData.holding).toFixed(2)}</div>
                </div>{' '}
                <div className="balancesDataRow">
                  <div>Amount: </div>
                  <div> {`${balancesData.amount || balancesData.amount === 0 ? parseFloat(balancesData.amount).toFixed(6) : '-'} ${balancesData.coin}`}</div>
                </div>
                {props.showBuyButton && (
                  <div
                    className="sendReceiveBuyMobileView mt-20"
                    onClick={() => {
                      props.setCoin(props.data.coin);
                      props.handleSend('buy');
                    }}
                  >
                    <img src={`${image_base_url}${buyIcon}`} alt="buyIcon" />
                    <div>Buy</div>
                  </div>
                )}
                <div
                  className="sendReceiveBuyMobileView"
                  onClick={() => {
                    props.setCoin(balancesData.coin);
                    props.handleReceive();
                  }}
                >
                  <img
                    className={b2cTheme === 'light' ? 'reciveIcon__dark' : ''}
                    src={`${image_base_url}${receiveIcon}`}
                    alt="receiveIcon"
                    style={{ marginTop: '-2px', marginRight:"-10px", width:"35px", height:"35px" }}
                  />
                  <div className={b2cTheme === 'light' ? 'reciveTitle__dark' : ''} data-e2e="receive-balance-table">
                    Receive
                  </div>
                </div>
                <div
                  className="sendReceiveBuyMobileView"
                  onClick={() => {
                    props.setCoin(balancesData.coin);
                    props.handleSend('receive');
                  }}
                >
                  <img src={`${image_base_url}${sendIcon}`} alt="sendIcon" />
                  <div data-e2e="send-balance-table">Send</div>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      ) : (
        <div>No data found //this is temp component</div>
      )}
    </div>
  );
};

export default b2cBalanceTable;

const SortByIcon = (props) => {
  return <i data-e2e="dropDownIcon" className={`fa fa-chevron-${props.orderBy === 'desc' ? 'down' : 'up'} BalanceTable_heading_sortIcon`} />;
};

const SingleTransactionTableRow = (props) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const b2cTheme = useSelector((state) => state.common.b2cTheme);

  return (
    <tr className="Balance_TableRow">
      <td className="BalanceTable_item">
        <img className="USTImages" src={`${image_base_url}${getCryptoIcon(props.data.coin)}`} alt="bitcoin" />
        <span>
          {' '}
          {props.data.coin ? props.data.coin : 'N/A'} / <span>{props.data.cryptoName ? props.data.cryptoName : 'N/A'}</span>
        </span>
      </td>
      <td className="BalanceTable_item">
        {props.data.holding || props.data.holding === 0 ? (
          <div>
            <div>{parseFloat(props.data.holding).toFixed(2)}%</div>
            <div className="barParant">
              <div className="barChild" style={{ width: `${props.data.holding <= 100 ? props.data.holding : 0}%` }}></div>
            </div>
          </div>
        ) : (
          <div>
            <div>0%</div>
            <div className="barParant">
              <div className="barChild" style={{ width: `0%` }}></div>
            </div>
          </div>
        )}
      </td>
      <td className="BalanceTable_item">
        {' '}
        <div>
          <div data-e2e-currency={props.data.coin ? props.data.coin : 'N/A'} data-e2e={props.data.amount || props.data.amount === 0 ? parseFloat(props.data.amount).toFixed(6) : '-'}>{props.data.amount || props.data.amount === 0 ? parseFloat(props.data.amount).toFixed(6) : '-'}</div>
          <div style={{ fontSize: '12px', opacity: '0.5' }} data-e2e-usd={props.data.coin ? props.data.coin : 'N/A'} data-e2e-usd-amount={props.data.USDAmount || props.data.USDAmount === 0 ? parseFloat(props.data.USDAmount).toFixed(2) : '-'} >
            {props.data.USDAmount || props.data.USDAmount === 0 ? parseFloat(props.data.USDAmount).toFixed(2) : '-'} USD
          </div>
        </div>
      </td>
      <td className="BalanceTable_item">
        <div>
          <div>${parseFloat(props.data.exchangeRate).toFixed(2)}</div>
        </div>
      </td>
      <td className="BalanceTable_item">
        <div className="lastColumn">
          <div
            className="sendReceiveBuy"
            onClick={() => {
              props.setCoinFridge(true)
              props.setCoin(props.data.coin);
              props.handleSend('receive');
            }}
          >
            <img src={`${image_base_url}${props.sendIcon}`} alt="sendIcon" />
            <div data-e2e="send-balance-table">Send</div>
          </div>
          <div
            className="sendReceiveBuy"
            onClick={() => {
              props.setCoinFridge(true)
              props.setCoin(props.data.coin);
              props.setBlockchain(getBlockchainName(props.data.coin));
              props.handleReceive();
            }}
            >
            <img
              className={b2cTheme === 'light' ? 'reciveIcon__dark' : ''}
              src={`${image_base_url}${props.receiveIcon}`}
              alt="receiveIcon"
              style={{ marginTop: '-2px' }}
            />
            <div className={b2cTheme === 'light' ? 'reciveTitle__dark' : ''} data-e2e="receive-balance-table">
              Receive
            </div>
          </div>
          {
            props.showBuyButton && <div className="sendReceiveBuy" onClick={() => { props.setCoinFridge(true); props.setCoin(props.data.coin); props.handleSend('buy'); }} >
              <img src={`${image_base_url}${props.buyIcon}`} alt="buyIcon" />
              <div data-e2e="buy-balance-table">Buy</div>
            </div>}
        </div>
      </td>
    </tr>
  );
};
